import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import '../App.css'; // Ensure this path is correct

function Home() {




  
  return (
    <div className="App">
      <header className="App-header">
        <h1 className="logo">OmniMinds</h1>
        <nav>
          <Link to="/" className="nav-link">Home</Link>
          <Link to="/translateapp" className="nav-link">Translate App</Link>
          <a href="https://chatculture.app" className="nav-link" target="_blank" rel="noopener noreferrer">ChatCulture</a>
        </nav>
      </header>
      <main className="hero">
        <h2>Welcome to OmniMinds</h2>
        <p>Explore our apps and connect to the world seamlessly.</p>
        <div className="cta-container">
          <Link to="/translateapp" className="cta-button">Discover TranslateApp</Link>
          <a href="https://chatculture.app" className="cta-button" target="_blank" rel="noopener noreferrer">Visit ChatCulture</a>
        </div>
      </main>
      <footer>
        <p>© 2024 OmniMinds. All rights reserved.</p>
      </footer>
    </div>
  );
}

export default Home;
