import React from "react";
import { Link } from "react-router-dom";
import { ReactComponent as AppleStoreSVG } from "../assets/AppleStoreSVG.svg";
import { ReactComponent as GooglePlaySVG } from "../assets/GooglePlaySVG.svg";
import styles from "./TranslateApp.module.css";
import Helmet from "react-helmet";
import Scrollspy from "react-scrollspy";

const appStoreUrl = "https://itunes.apple.com/us/app/your-app-id";
const playStoreUrl = "https://play.google.com/store/apps/details?id=your-package-name";

function TranslateApp() {
  return (
    <div className={styles.container}>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Translate Text & Voice: Precision in Every Word</title>
        <meta name="description" content="Revolutionize your global communication with ChatCulture, featuring Chat Translation, Camera Translation, AR Translation, Document Translation, Object Identification, and Voice-to-Text. Experience the future of language translation today." />
        <meta property="og:type" content="website" />
        <meta property="og:site_name" content="ChatCulture" />
        <meta property="og:title" content="ChatCulture: Innovative Translation and Communication" />
        <meta property="og:description" content="Revolutionize your global communication with ChatCulture, featuring Chat Translation, Camera Translation, AR Translation, Document Translation, Object Identification, and Voice-to-Text. Experience the future of language translation today." />
        <meta property="og:url" content={window.location.href} />
        <link rel="canonical" href={window.location.href} />
      </Helmet>

      <header className={styles.header}>
  <div className={styles.logoMain}>
    Translate Text & Voice
  </div>
  <nav className={styles.navLinks}>
    <Scrollspy items={["download", "home"]} currentClassName="active" offset={-300}>
      {/* Add Link component with appStoreUrl */}
      <Link to={appStoreUrl} className={styles.link} target="_blank">
        Download
      </Link>
      {/* Use Link component to navigate to home ('/') */}
      <Link to="/" className={styles.link}>
        Home
      </Link>
    </Scrollspy>
  </nav>
</header>
      <main className={styles.main}>
        <section className={styles.section}>
        <h1>Speak Like a Native: Experience Perfect Pronunciation. </h1>
          <p>Discover the simplicity of Translate Text & Voice, the lightweight app designed to make language barriers a thing of the past. Enjoy intuitive, user-friendly text-to-text, text-to-voice, and voice-to-text features—all for free.</p>
          <div className={styles.description}>
            <h3>Features:</h3>
            <ul className={styles.featuresList}>
            <li><strong>Accurate Pronunciation:</strong> Understand and be understood with speech that sounds like a native speaker.</li>
              <li><strong>Extensive Language Support:</strong>  Easily switch among 77+ languages for precise translations.</li>
              <li><strong>Simple to Use:</strong> A user-friendly app that makes multilingual communication quick and easy.</li>
            </ul>
          </div>
          <div className={styles.downloadSection}>
            <h2 className={styles.downloadHeader}>Download Now</h2>
            <div className={styles.appDownload}>
              <a href={appStoreUrl} target="_blank" rel="noopener noreferrer">
                <AppleStoreSVG className={styles.storeIcon} />
              </a>
              <a href={playStoreUrl} target="_blank" rel="noopener noreferrer">
                <GooglePlaySVG className={styles.storeIcon} />
              </a>
            </div>
          </div>
        </section>
      </main>

      <footer className={styles.footer}>
        <div className={styles.footerColumns}>
          <div className={styles.footerColumn}>
          <h4>About Translate Text & Voice</h4>
            <p>At the heart of global understanding, Translate Text & Voice paves the way for flawless communication. Adopt the power of perfect pronunciation and broad language support to make every interaction count.</p>
           </div>
          <div className={styles.footerColumn}>
            <h4>Quick Links</h4>
            <ul>
              <li>
                <a href="terms">Terms and Conditions</a>
              </li>
              <li>
                <a href="privacy">Privacy Policy</a>
              </li>
              <li>
                <a
                  href="mailto:chatcultureapp@gmail.com"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Contact Us
                </a>
              </li>
            </ul>
          </div>
        </div>
        <div className={styles.footerBottom}>
          <span>© {new Date().getFullYear()} Translate Text & Voice. All rights reserved.</span>
        </div>
      </footer>
    </div>
  );
}

export default TranslateApp;
