import React, { useEffect } from 'react';
import { Routes, Route, useLocation } from 'react-router-dom';
import Home from './components/Home';
import TranslateApp from './components/TranslateApp';
import Privacy from './components/Privacy';
import Terms from './components/Terms';
import './App.css';

function App() {
  const location = useLocation();

  useEffect(() => {
    const updateFavicon = () => {
      const existingLink = document.querySelector("link[rel*='icon']");
      if (location.pathname === '/') {
        if (existingLink) {
          existingLink.parentNode.removeChild(existingLink);
        }
      } else {
        const faviconPath = `${process.env.PUBLIC_URL}/assets/favicon.ico`; // Adjusted path
        if (!existingLink) {
          const link = document.createElement('link');
          link.type = 'image/x-icon';
          link.rel = 'shortcut icon';
          link.href = faviconPath;
          document.head.appendChild(link);
        } else {
          existingLink.href = faviconPath; // Update the href if the icon already exists
        }
      }
    };

    updateFavicon();
  }, [location]);

  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/translateapp" element={<TranslateApp />} />
      <Route path="/privacy" element={<Privacy />} />
      <Route path="/terms" element={<Terms />} />
    </Routes>
  );
}

export default App;
